<template>
  <div class="contain">
    <p>新石器网络平台用户协议</p>
    <p>发布日期：2022年02月15日</p>
    <p>
      感谢您使用新石器网络平台。请您审慎阅读并选择是否接受用户协议（以下简称“本协议”），尤其务必重视本协议中加粗的条款（可能存在限制您的权利或加重您的义务的情形）。
    </p>
    <p>第一条、协议及服务确认</p>
    <p class="m30">
      1.新石器网络平台是指提供新石器产品或服务的官方网站、移动应用、点餐终端、网络店铺、社交媒体等网络平台。
    </p>
    <p class="m30">
      2.新石器网络平台的服务提供者为新石器和/或其关联公司以及特许加盟商（以下合称“服务提供者”或“我们”），根据您所选择的服务类别，分别由相应的服务提供者向您提供。
    </p>
    <p class="m30">
      3.如您为非完全民事行为能力人，请告知您的监护人，并在您的监护人指导下阅读本协议并决定是否接受本协议。若您是未满18周岁的未成年人，在向我们提交个人信息前，请务必征得您的父母/监护人同意。
    </p>
    <p class="m30">
      4.为了保证我们的服务的及时性、准确性，烦请您务必确认您提供的信息的完整性、真实性、时效性，必要时我们可能会进行实名制验证。一旦发现您的信息违反法律规定或者不符合业务规则的，我们可能会依法采取相应的措施，包括但不限于中止或注销您的账户，或者停止为您提供产品或服务。
    </p>
    <p class="m30">
      5.为了更好的向您提供产品或服务，我们可能会不时修改本协议，或根据本协议制定、修订各类业务规则并在我们相关板块公布，并以适当的方式提醒您，烦请您务必认真阅读。若您在本协议或业务规则内容变更后继续使用我们服务的，表示您已充分阅读、理解并接受修改后的协议或业务规则。若您对该等变更有任何意见的，烦请按照本协议披露的方式联系我们。
    </p>
    <p class="m30">
      6.为了保证我们的服务的安全性、适当性，我们可能不时更新、改变、限制、中止或终止全部或部分我们的产品或服务。此时，我们会以适当的方式提前通知您，烦请您自主决定是否继续使用我们的产品或服务。
    </p>
    <p class="m30">
      7.尽管我们会尽力确保服务的有效性和可用性，但因电信故障、黑客攻击或政策变动等不可抗力因素，可能导致我们的部分或全部服务无效或存在瑕疵，我们会尽快以适当的方式通知您，您理解并接受该等情形的出现并放弃索赔的权利。
    </p>
    <p class="m30">
      8.我们可能会与第三方合作，并由第三方向您提供相关的产品、服务或信息（包括但不限于广告服务、支付服务、定位服务等）。在此情况下，您理解并同意，受限于技术条件及业务模式，我们可能无法一一确保该第三方提供的产品、服务或信息的可用性、有效性和合法性。烦请您慎重使用第三方产品、服务或信息，并确保充分知悉并理解其服务协议、政策或规则。
    </p>
    <p>第二条、账户管理</p>
    <p class="m30">
      1. 您注册的账户仅限于您本人使用，不可转让、租借、赠与或继承。
    </p>
    <p class="m30">
      2.您的账户注册/绑定成功后，将由您自行设置名称、收货地址、手机号码和管理密码、验证码等账户信息。您可以根据我们的业务规则改变密码。您应谨慎合理的保存、使用您的账户、密码或验证码，并对利用您的您账户、密码或验证码完成管理权限验证而实施的所有行为、活动及事件负责。
    </p>
    <p class="m30">
      3.您理解并同意，为了更好的为您提供服务或其他合理目的，我们及我们的关联方可以收集和使用您的个人信息及数据。
    </p>
    <p class="m30">
      4.您理解并同意，我们向您注册时预留的地址、电话、邮箱等任一方式发送信息，或者发送站内信息，即视为我们向您履行了送达、通知义务。
    </p>
    <p>第三条、订单管理</p>
    <p class="m30">
      1.您根据本协议及业务规则，通过点击、确认或类似方式签署的电子协议、订单，即视为您本人真实意愿并具有法律效力。
    </p>
    <p class="m30">
      2.若您未能及时对交易状态进行修改、确认，或未能提交相关申请，可能会导致相关订单被取消或以不符合您的意愿的形式提供，因此所引起的责任由您承担。
    </p>
    <p class="m30">
      3.您理解，我们提供的服务或产品有赖于系统的准确运行及操作。若出现系统差错、故障或其他原因引发的展示错误、您或我们不当获利等情形，您同意我们可以采取更正差错、扣划款项、订单回转等适当纠正措施。
    </p>
    <p class="m30">
      4.您参与相关促销活动，或者使用相关优惠券（含积分、抵用券、折扣券等，下同）后的支付结算规则，参照届时有效的规则执行，烦请您务必仔细阅读相关规则。
    </p>
    <p>第四条、您的行为规范承诺</p>
    <p class="m30">1. 在使用我们服务过程中，您承诺遵守以下约定：</p>
    <p class="m60">
      1)在使用我们的服务过程中实施的所有行为均遵守国家法律、法规、政策及我们的业务规则，不违背社会公共利益或公共道德，不损害他人的合法权益。
    </p>
    <p class="m60">
      2)不发布法律、法规、政策及业务规则禁止发布的信息，不发布涉嫌侵犯他人知识产权或其它合法权益的信息。
    </p>
    <p class="m60">
      3)不使用任何装置、软件或程序干预或试图干预我们的软件、活动、信息的正常运作。
    </p>
    <p class="m60">
      4)不得进行危害网络安全的行为，包括但不限于：使用未经许可的数据或进入未经许可的账户；未经允许进入网络或系统并获取、删除、修改、增加数据；非法提供、获取他人的个人信息；或恶意干扰我们的排序、展示或评价机制等等。
    </p>
    <p class="m60">
      5)不得利用非正常渠道、手段或其他干扰行为，包括但不限于批量注册账户、恶意刷单等行为，获取我们提供的积分权益、优惠券、折扣券、免费券、活动补贴等优惠权益；或通过转卖、分享前述优惠权益以非法牟利，或其他影响品牌市场价格、生产经营、品牌名誉的行为；
    </p>
    <p class="m60">
      6)不得将我们为您提供的服务，包括但不限于自助点餐、会员服务用于非本人消费或任何商业用途；
    </p>
    <p class="m60">
      7)不得向我们员工及其直系亲属提供任何形式的可能涉嫌构成商业贿赂的好处或利益。
    </p>
    <p class="m60">8) 不得发布任何有损我们商誉的言论。</p>
    <p class="m30">2. 违反上述承诺时，您理解并同意：</p>
    <p class="m60">
      1)根据相关法律、政策或者业务规则，我们将采取相应措施，包括但不限于取消订单，扣回货款、撤销积分、直接屏蔽、删除数据信息，或直接停止/限制您使用服务、限制参加活动、限制领取优惠权益、终止/注销您的账户，等等。
    </p>
    <p class="m60">
      2)对任意第三方造成损害的，您均应当以自己的名义独立承担所有的法律责任，并应确保我们免于承担因此产生的损失。因此使我们遭受任何损失，或受到任何第三方的索赔，或受到任何行政管理部门的处罚，您应当赔偿我们因此造成的损失及发生的合理费用，包括但不限于调查费、公证费、差旅费、律师费等。
    </p>
    <p>第五条、知识产权保护声明</p>
    <p class="m30">
      1.我们提供的产品或服务过程中的全部内容的知识产权均属于新石器或其关联公司所有，该等内容包括但不限于文字、数据、源代码、软件、图片、视音频及其他全部内容（以下称“内容”）。未经我们事先书面同意，您承诺不以任何方式复制、传播、出版、公布、展示、反向编译，或许可他人使用，否则我们将依法追究法律责任。
    </p>
    <p class="m30">
      2.若您提供或发布的任何内容侵犯第三方合法权益的，我们会删除或断开相关链接，因此造成的责任由您自行承担。
    </p>
    <p class="m30">
      3.若您发现有关内容侵犯您的知识产权的，烦请联系我们，并提供相应权利证明，我们核实后尽快删除或断开相关链接。
    </p>
    <p>第六条、法律适用与管辖</p>
    <p class="m30">
      1.如本协议中的任何条款无论因何种原因完全或部分无效或不具有执行力，则应认为该条款可与本协议相分割，并可被尽可能接近各方意图的、能够保留本协议要求的经济目的的、有效的新条款所取代，而且，在此情况下，本协议的其他条款仍然完全有效并具有约束力。
    </p>
    <p class="m30">
      2.本协议及其修订的有效性、履行与本协议及其修订效力有关的所有事宜，将受中国法律管辖，任何争议仅适用中华人民共和国法律。
    </p>
    <p class="m30">
      3.因本协议所引起的您与我们的任何纠纷或争议，首先应友好协商解决；协商不成的，任一方有权将纠纷或争议提交被告所在地有管辖权的人民法院诉讼解决。
    </p>
  </div>
</template>

<script>
export default {
  name: "UserPrivacy",
  components: {},
  data() {
    return {};
  },

  methods: {},

  created: function () {},
};
</script>

<style scoped lang="scss">
.contain {
  width: 100%;
  height: 100%;
  padding: 0.3rem;
  padding-top: 0;
  font-family: PingFangSC-Regular;
  font-size: 0.26rem;
  letter-spacing: 0;
  overflow: scroll;
  @include font_color("agreement_color");
  @include background_color("agreement_bg");

  .m30 {
    margin-left: 0.6rem;
  }
  .m60 {
    margin-left: 1.2rem;
  }
}
</style>
